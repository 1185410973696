import './App.css';
import ReactGA from 'react-ga4';
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import MaintenancePage from './pages/MaintenancePage';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import OfflineBanner from './components/OfflineBanner/OfflineBanner';

import Faq from './pages/Faq';
import Git from './pages/Git';
import Admin from './pages/Admin';
import Layer from './pages/Layer';
import Terms from './pages/Terms';
import MyCars from './pages/MyCars';
import Contact from './pages/Contact';
import CarList from './pages/CarList';
import MyProfile from './pages/MyProfile';
import HomePage from './pages/HomePage/HomePage';
import PrivacyPolicy from './pages/PrivacyPolicy';

import { connect } from 'react-redux';
import Vendedor from './pages/Vendedor';
import MyDealer from './pages/MyDealer';
import CarDetails from './pages/CarDetails';
import Proxy from './components/Proxy/Proxy';
import SellYourCar from './pages/SellYourCar';
import EditCar from './pages/EditCar/EditCar';
import PageNotFound from './pages/PageNotFound';
import DealerSignUp from './pages/DealerSignUp';
import Catalogo from './pages/Catalogo/Catalogo';
import AddReview from './pages/Reviews/AddReview';
import CarDetailsReview from './pages/CarDetailsReview';
import CarReview from './components/CarReview/CarReview';
import AdminLinks from './components/AdminLinks/AdminLinks';
import { setJwtUserRequest } from './store/UserStore/userActions';
import { getTokenDataFromLocalStorage } from './helpers/jwtService';
import ChangeCarBrands from './components/ChangeCarBrands/ChangeCarBrands';

import AdminVideoTracker from './components/AdminVideoTracker/AdminVideoTracker';
import AdminFreeToPremium from './components/AdminFreeToPremium/AdminFreeToPremium';


const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
ReactGA.initialize(GOOGLE_ANALYTICS);
ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

// ============ ZONA DE CUIDADO ============ todo en false por defecto
const showMaintenancePage = true; // ⛔️⛔️⛔️ !!! BLOQUEA TODA LA PÁGINA !!! ⛔️⛔️⛔️
const showRedMovingBanner = false;
// ============ ZONA DE CUIDADO ============ todo en false por defecto

function App({ setJwt }) {
  useEffect(() => {
    const authUser = async (token) => {
      const isLoggedIn = await setJwt(token);
      return isLoggedIn;
    };

    const token = getTokenDataFromLocalStorage();
    const date = new Date();
    if (token != null && token.exp >= date) {
      authUser(token);
    }
  }, []);

  if (showMaintenancePage) {
    return <MaintenancePage />;
  }

  return (
    <Layer>
      <HelmetProvider>
        <Helmet>
          <title>Autos Nova | La plataforma de autos de Costa Rica</title>
          <meta
            name="description"
            content="La plataforma de autos de Costa Rica. Publica tu auto en minutos. Encuentra tu próximo auto en Autos Nova."
          />
          <meta
            property="og:description"
            content="La plataforma de autos de Costa Rica. Publica tu auto en minutos. Encuentra tu próximo auto en Autos Nova."
          />

          <meta
            name="keywords"
            content="Autos Nova, autos nova cr, autosnovacr, compra y venta de autos costa rica, autos usados costa rica, compra de autos usados, venta de autos usados, autos en venta Costa Rica, vcr"
          />
          <meta name="author" content="Autos Nova" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Autos Nova" />
          <meta property="og:locale" content="es_CR" />
          <link rel="canonical" href="https://AutosNovaCR.com" />
          <meta property="og:image" content="%PUBLIC_URL%/an_og.png" />
        </Helmet>
        {showRedMovingBanner && <OfflineBanner />}
        <Routes>
          {/* TODO: Revisar con Moises para definir nombre de rutas */}
          <Route path="/" element={<HomePage />} />
          <Route path="/en-venta" element={<CarList />} />
          <Route path="/vender-mi-carro/" element={<SellYourCar />} />
          <Route path="/vender-mi-carro/:carUuid" element={<SellYourCar />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacidad" element={<PrivacyPolicy />} />
          <Route path="/terminos" element={<Terms />} />
          <Route path="/contacto" element={<Contact />} />

          <Route path="/carro/:carUuid" element={<CarDetails />} />
          <Route path="/mis-anuncios" element={<MyCars />} />
          <Route path="/mi-perfil" element={<MyProfile />} />
          <Route path="/RegistroNegocio" element={<DealerSignUp />} />

          <Route path="/RegistroNegocio/catalogo/:dealerUrl" element={<Catalogo />} />

          <Route path="/vendedor" element={<Vendedor />} />
          {/* <Route path="/vendedor/:customUrl/:dealerName" element={<MyDealer />} /> */}
          <Route path="/vendedor/:customUrl" element={<MyDealer />} />
          <Route path="/vendedor/:customUrl/catalogo" element={<Catalogo />} />

          <Route path="/editar-anuncio/:anuncioId" element={<EditCar />} />
          <Route
            path="/agregar-review/:uuid/:dealerName"
            element={<AddReview />}
          />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/car-brands" element={<ChangeCarBrands />} />
          <Route path="/admin/car-review" element={<CarReview />} />
          <Route path="/admin/proxy" element={<Proxy />} />
          <Route path="/admin/links" element={<AdminLinks />} />
          <Route path="/admin/videoTracker" element={<AdminVideoTracker />} />
          <Route path="/admin/FreeToPremium" element={<AdminFreeToPremium />} />
          <Route path="/admin/review/:carUuid" element={<CarDetailsReview />} />
          <Route path="/git" element={<Git />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </HelmetProvider>
    </Layer>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    setJwt: (data, token) => dispatch(setJwtUserRequest(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    // carInfo: state.CarOptions,
    // locationInfo: state.LocationInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
