import { useState } from 'react';
import ReactGA from 'react-ga4';

import logo from '../images/appImages/logo-vcr2.png';
import sunset from '../images/appImages/sunset.jpeg';

// Import all the cute images
import cute_01 from '../images/appImages/cute/cute_01.jpg';
import cute_02 from '../images/appImages/cute/cute_02.jpg';
import cute_03 from '../images/appImages/cute/cute_03.jpg';
import cute_04 from '../images/appImages/cute/cute_04.jpg';
import cute_05 from '../images/appImages/cute/cute_05.jpg';
import cute_06 from '../images/appImages/cute/cute_06.jpg';
import cute_07 from '../images/appImages/cute/cute_07.jpg';
import cute_08 from '../images/appImages/cute/cute_08.jpg';
import cute_09 from '../images/appImages/cute/cute_09.jpg';
import cute_10 from '../images/appImages/cute/cute_10.jpg';
import cute_11 from '../images/appImages/cute/cute_11.jpg';
import cute_12 from '../images/appImages/cute/cute_12.jpg';
import cute_13 from '../images/appImages/cute/cute_13.jpg';

function MaintenancePage() {
  // TODO: Arreglar GOOGLE Analytics
  // Initialize Google Analytics
  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: 'En mantenimiento' });

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  // }, []);

  // Create an array with all the cute images
  const cuteImages = [
    cute_01,
    cute_02,
    cute_03,
    cute_04,
    cute_05,
    cute_06,
    cute_07,
    cute_08,
    cute_09,
    cute_10,
    cute_11,
    cute_12,
    cute_13,
  ];

  // Function to generate a random image index
  const getRandomIndex = () => {
    return Math.floor(Math.random() * cuteImages.length);
  };

  const [currentImage, setCurrentImage] = useState(
    cuteImages[getRandomIndex()]
  );

  // Function to handle image click
  const handleImageClick = () => {
    // Generate a random index to pick a new cute image
    const randomIndex = getRandomIndex();
    const randomCuteImage = cuteImages[randomIndex];
    setCurrentImage(randomCuteImage);
  };

  return (
    <div className="splash-screen">
      {/* <img
        // src={logo}
        alt="Autos Nova Logo"
        style={{ width: '150px', marginBottom: '20px' }}
      /> */}
      <h1 style={{ fontSize: '50px', fontWeight: 'bold' }}>
        Autos Nova
      </h1>
      <h3 style={{ fontSize: '50px', fontWeight: 'bold' }}>
        Dentro de poco regresamos...
      </h3>
      <br />

      <p style={{ fontSize: '20px' }}>
        Estamos realizando cambios en la plataforma
      </p>
      <br />

      <img
        // src={currentImage}
        src={sunset}
        alt="Cute"
        style={{
          width: '100%',
          maxWidth: '600px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
          marginBottom: '20px',
          cursor: 'pointer',
        }}
        onClick={handleImageClick}
      />
    </div>
  );
}

export default MaintenancePage;
